//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import ForgotPasswordDialog from '@/components/common/forgot-password-dialog'
import {mapActions} from "vuex";
import {adminCodes} from "@/enums/adminSettings";

export default {
  name: 'LoginCard',
  components: {
    LabeledControlLayout,
    ForgotPasswordDialog,
  },
  data() {
    return {
      forgotPasswordDialog: false,
      createNewCustomer: true,
      signInForm: {
        data: {
          email: '',
          password: '',
        },
        emailRules: [
          (v) => !!v || 'Required',
          (v) => /.+@.+\..+/.test(v) || this.$t('Please enter a valid email address'),
        ],
        rules: {
          required: (value) => !!value || 'Required',
          min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        },
        rememberMe: false,
        showPassword: false,
        valid: false,
      },
      loginContantInfo: {
        phone_number:'531-005-555',
        email_address:'fusion@gopiro.com'
      }
    }
  },
  methods: {
    ...mapActions('backoffice/settings', ['fetchAdminSettingByCode']),
    async signIn() {
      if (this.$refs.signInForm.validate()) {
        try {
          const response = await this.$auth.loginWith('local', {
            data: {
              username: this.signInForm.data.email,
              password: this.signInForm.data.password,
            },
          })

          if (response.status === 200 && response.data.token) {
            this.$toast.success('Logged in!')
            this.$emit('loggedIn')
          } else {
            this.$toast.error('Invalid username or password!')
          }
        } catch (err) {
          this.$toast.error('Invalid username or password!')
        }
      }
    },
    signUp() {
      this.$emit('signUpHandler')
    },
    showDialog() {
      this.forgotPasswordDialog = true
    },
    doResetPassword() {
      // todo
    },
  },
  async mounted() {
    const contentData = (
      await this.$axios.get(`/contents/login-content-info`, {
        // /catch
        params: {version:'published'},
      })
    ).data

    if (contentData.id) {
      this.loginContantInfo.email_address = contentData.details.email_address.fieldValue.value;
      this.loginContantInfo.phone_number = contentData.details.phone_number.fieldValue.value;
    }

    const createNewCustomerSetting = await this.fetchAdminSettingByCode(
      adminCodes.CREATE_CUSTOMER_FOR_NEW_REGISTRATION
    )
    this.createNewCustomer = createNewCustomerSetting && createNewCustomerSetting.value === 'true'

  }
}
